"use strict";

module.exports = {
    array: require("./array"),
    function: require("./function"),
    map: require("./map"),
    object: require("./object"),
    set: require("./set"),
    string: require("./string"),
};
